<template>
  <div class="flex w-full bg-img vx-row no-gutter items-center justify-center mt-10" id="page-login">
    <div class="vx-col">
      <div class="vx-row no-gutter justify-center items-center ">
        <div class="vx-col lg:block ">
          <center>
            <img src="@/assets/logo.png" alt="logo" class="logo mx-auto lg:w-1/4 mb-10" />
          </center>
        </div>

        <div class="vx-col mx-auto lg:w-3/4 pagemax-full">


          <span id="download_loader" v-if="!$store.state.portfolio"></span>

          <div v-if="$store.state.portfolio">


            <div v-for="investment in $store.state.portfolio" v-bind:key="investment.id">
              <div v-for="item in investment.items" v-bind:key="item.id">

                <div v-if="(item.wine_id == $route.params.id && investment.id == $route.params.orderId)" class="">
                  <!-- {{$store.state.portfolio}} -->


                  <div class="grid grid-cols-1 gap-4 pb-2 float-right">
                    <div v-if="item.livePrices.data['price-average'] && !item.livePrices.data['price-max']" class="grid">
                      <span class="float-right oeno_portfolio_price">{{ currencyFormatter(getPrice(investment, item))
                        }}&nbsp;{{ getCurrency(investment, item) }} </span>
                      <span :class="[
                        priceUp(investment, item)
                          ? 'float-right oeno_portfolio_price_up'
                          : 'float-right oeno_portfolio_price_down',
                      ]">{{ getChange(investment, item) }}</span>
                    </div>
                    <div v-if="item.livePrices.data['price-max']&& item.total_unsold_brokerage==0" class="grid">

                      <span class="float-right oeno_portfolio_price">{{ currencyFormatter(getPriceMax(investment, item))
                        }}&nbsp;{{ getCurrency(investment, item) }} Max</span>
                      <span :class="[
                        priceUpMax(investment, item)
                          ? 'float-right oeno_portfolio_price_up'
                          : 'float-right oeno_portfolio_price_down',
                      ]">{{ getChangeMax(investment, item) }}</span>
                    </div>
                    <div v-if="item.livePrices.data['price-min']&& item.total_unsold_brokerage==0" class="grid">
                      <span class="float-right oeno_portfolio_price">{{ currencyFormatter(getPriceMin(investment, item))
                        }}&nbsp;{{ getCurrency(investment, item) }} Min</span>
                      <span :class="[
                        priceUpMin(investment, item)
                          ? 'float-right oeno_portfolio_price_up'
                          : 'float-right oeno_portfolio_price_down',
                      ]">{{ getChangeMin(investment, item) }}</span>
                    </div>

                  </div>
                  <button type="button" @click="closeButton()"
                    class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span class="sr-only">Close menu</span>
                    <!-- Heroicon name: outline/x -->
                    <svg class="h-10 w-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                      stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>


                  <div class="flex justify-center  ">
                    <img :src="wineImage(item)" class="oeno_portfolio_wineimage w-1/6 h-1/6	"
                      onerror="this.style.display='none'" />
                  </div>
                  <!-- Display line chart only if livex chart data available -->
                  <LineChartPlotly v-if="(item.livePrices.data.livex.length > 0)" class="w-auto " :graphData="item" />


                  <div class="col-span-3 mt-10">
                    <span class="text-3xl font-bold oeno_portfolio_title">{{
                      item.wines.name
                    }}</span><br />
                    <span class="text-xl font-medium font-Montserrat">{{
                      winary(item)
                    }}</span><br />
                    <span class="font-bold font-Montserrat">{{
                      showquantity(item)
                    }}</span>
                  </div>
                  <div class="col-span-5">
                    <hr class="oeno_divider_sm" />
                  </div>
                  <div class="col-span-3 ">
                    <span class="priceDetails-sm font-bold">Purchase Price</span>
                    <span class="priceDetails-sm float-right">{{ currencyFormatter(getPurchasePrice(item))
                      }} {{ getCurrency(investment, item) }}</span>

                  </div>
                  <br />
                  <div class="col-span-3 ">
                    <span class="priceDetails-sm font-bold">Quantity</span>
                    <span class="priceDetails-sm float-right">{{ getQuantity(item)
                      }}</span>
                  </div>
                  <br />
                  <br />
                  <br />
                  <div>
                 


                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChartPlotly from '../../../components/LineChartPlotly.vue';

export default {
  data() {

    return {
      getTotalPrice: "",
      getTotalPriceDiff: ""
    };
  },
  components: {
    LineChartPlotly
  },
  mounted() {

  },
  computed: {
    getUserName() {
      var userName = "";
      if (this.$store.state.user) {
        userName =
          this.$store.state.user.firstname +
          " " +
          this.$store.state.user.surname;
      }
      return userName;
    },
    getUserReference() {
      var reference = "";
      if (this.$store.state.user) {
        reference = "(" + this.$store.state.user.unique_number + ")";
      }
      return reference;
    },
    getUserBroker() {
      var broker = "";
      if (this.$store.state.user) {
        broker =
          this.$store.state.user.brokerName +
          " " +
          this.$store.state.user.brokerEmail;
      }
      return broker;
    },
    getTotals() {
      const values = [];
      // let topWine = null;
      let maxPerformance = -Infinity;
      this.$store.state.portfolio.forEach((investment) => {
        investment.items.forEach((item, key) => {
          const itemPrice = this.getValuesForItem(investment, item);

          const performance = parseFloat(itemPrice.changePrice);

          if (performance > maxPerformance) {
            maxPerformance = performance;
            this.topWine = {
              wine: item.wines,
              currency: itemPrice.currency,
              changePrice: parseFloat(itemPrice.changePriceMax),
              total: parseFloat(itemPrice.total_max),
              buytotal: parseFloat(itemPrice.buytotal) + parseFloat(investment.tax_amount),
              investment_tax_amount: parseFloat(investment.tax_amount) // Including the tax amount for clarity
            };
          }
          let pos = values.findIndex(
            (value) => value.currency === itemPrice.currency
          );

          if (pos > -1) {

            values[pos].total += parseFloat(itemPrice.total);
            values[pos].total_min += parseFloat(itemPrice.total_min);
            values[pos].total_max += parseFloat(itemPrice.total_max);

            values[pos].buytotal += parseFloat(itemPrice.buytotal);

            // Add tax amount to the last item
            if (key === investment.items.length - 1) {
              values[pos].buytotal += parseFloat(investment.tax_amount);
            }

            values[pos].changePrice += parseFloat(itemPrice.changePrice);
            values[pos].changePriceMin += parseFloat(itemPrice.changePriceMin);
            values[pos].changePriceMax += parseFloat(itemPrice.changePriceMax);

          } else {
            values.push({
              currency: itemPrice.currency,

              changePrice: parseFloat(itemPrice.changePrice),
              changePriceMin: parseFloat(itemPrice.changePriceMin),
              changePriceMax: parseFloat(itemPrice.changePriceMax),

              total: parseFloat(itemPrice.total),
              total_min: parseFloat(itemPrice.total_min),
              total_max: parseFloat(itemPrice.total_max),

              buytotal:
                parseFloat(itemPrice.buytotal) +
                parseFloat(investment.tax_amount),
            });
          }

        });
      });


      return values;
    },
  },
  methods: {
    getQuantity(item) {
      return (item.quantity * item.wines.units_per_case);
    },
    getPurchasePrice(item) {
      return (item.bottle_price * item.quantity *
        item.wines.units_per_case);
    },
    itemProfit(item) {

      return item.livePrices.data['price-average'] - item.bottle_price;
    },
    closeButton() {
      history.go(-1)
    },

    winary(wine) {
      var winary = "n/a";
      var vintage = "";
      var size = "";

      if (wine.wines) {
        if (wine.wines.winery) {
          vintage = wine.wines.vintage;
          if (wine.wines.winery.name) {
            winary = wine.wines.winery.name;
          }
          if (wine.wines.size.name) {
            size = wine.wines.size.name;
          }
        }
      }

      winary = winary + " " + vintage;

      if (size != "") {
        winary = winary + " " + size + "ML";
      }

      return winary;
    },
    showquantity(wine) {
      var quantity = 0;
      var unitsPerCase = 0;
      var retStr = "";

      if (wine.quantity) {
        quantity = parseInt(wine.quantity);
      }
      if (wine.wines) {
        if (wine.wines.units_per_case) {
          unitsPerCase = parseInt(wine.wines.units_per_case);
        }
      }

      if (quantity > 0) {
        if (quantity > 1) {
          retStr = quantity + " Cases";
        } else {
          retStr = quantity + " Case";
        }
      }

      if (unitsPerCase > 0) {
        if (unitsPerCase > 1) {
          retStr = retStr + " (" + unitsPerCase + " bottles per case)";
        } else {
          retStr = retStr + " (" + unitsPerCase + " bottle per case)";
        }
      }

      return retStr;
    },

    wineImage(wine) {
      var wineimageurl = process.env.VUE_APP_WINE_DUMMY_IMAGE;

      if (wine.wines) {
        if (wine.wines.images) {
          if (wine.wines.images[0]) {
            wineimageurl =
              process.env.VUE_APP_WINE_IMAGE_PATH + wine.wines.images[0].name;
          }
        }
      }

      return wineimageurl;
    },

    priceUpMin(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      if (priceValues.changePriceMin >= 0) {
        return true;
      } else {
        return false;
      }
    },

    priceUpMax(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      if (priceValues.changePriceMax >= 0) {
        return true;
      } else {
        return false;
      }
    },

    getChange(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      return this.currencyFormatter(priceValues.changePrice) + "(" + this.currencyFormatter(priceValues.changePerc) + "%)";
    },
    getChangeMin(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      return this.currencyFormatter(priceValues.changePriceMin) + "(" + this.currencyFormatter(priceValues.changePercMin) + "%)";
    },

    getChangeMax(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      return this.currencyFormatter(priceValues.changePriceMax) + "(" + this.currencyFormatter(priceValues.changePercMax) + "%)";
    },
    getValuesForItem(investment, wine) {


      var buytotal =
        wine.quantity2 *
        wine.wines.units_per_case * wine.bottle_price;

      var total =
        parseInt(
          parseFloat(
            (wine.total_bottles - wine.total_unsold_brokerage) *

            wine.livePrices.data["price-average"]
          ) * 100
        ) / 100;
      var total_min =
        parseInt(
          parseFloat(
            (wine.total_bottles - wine.total_unsold_brokerage) *

            wine.livePrices.data["price-min"]
          ) * 100
        ) / 100;
      var total_max =
        parseInt(
          parseFloat(
            (wine.total_bottles - wine.total_unsold_brokerage) *

            wine.livePrices.data["price-max"]
          ) * 100
        ) / 100;

      // console.log(total, wine.livePrices.data["price-average"], wine.wines.units_per_case, wine.bottle_price, wine.quantity2, wine.quantity, wine.wines.name, wine)
      if (wine.livePrices.data["price-average"] == 0) {
        total = buytotal;
      }



      var changePerc =
        ((parseFloat(total) - parseFloat(buytotal)) / parseFloat(buytotal)) * 100;

      var changePercMin =
        ((parseFloat(total_min) - parseFloat(buytotal)) / parseFloat(buytotal)) * 100;

      var changePercMax =
        ((parseFloat(total_max) - parseFloat(buytotal)) / parseFloat(buytotal)) * 100;
      var changePrice = parseFloat(total) - parseFloat(buytotal);
      //       var changePrice =
      //         parseFloat(total) - parseFloat(buytotalCurrentAvailBrok);
      var changePriceMin =
        parseFloat(total_min) - parseFloat(buytotal);
      var changePriceMax =
        parseFloat(total_max) - parseFloat(buytotal);
      return {
        changePerc: changePerc,
        changePercMin: changePercMin,

        changePercMax: changePercMax,

        changePrice: changePrice,
        changePriceMin: changePriceMin,
        changePriceMax: changePriceMax,
        total: total,
        total_max: total_max,
        total_min: total_min,
        currency: investment.currency,
      };
    },

    getPrice(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);
      this.getTotalPrice = priceValues.total;
      return this.getTotalPrice;
    },
    getPriceMin(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      return priceValues.total_min;
    },
    getPriceMax(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      return priceValues.total_max;
    },
    getCurrency(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      return priceValues.currency;
    },

    getChangeTotal(total) {


      var changePerc = (total.changePrice / (total.total - total.changePrice)) * 100;

      return this.currencyFormatter(total.changePrice) + "(" + changePerc.toFixed(2) + "%)";
    },
    getChangeTotalMin(total) {


      var changePerc = (total.changePriceMin / (total.total_min - total.changePriceMin)) * 100;

      return this.currencyFormatter(total.changePriceMin) + "(" + changePerc.toFixed(2) + "%)";
    },
    getChangeTotalMax(total) {


      var changePerc = (total.changePriceMax / (total.total_max - total.changePriceMax)) * 100;

      return this.currencyFormatter(total.changePriceMax) + "(" + changePerc.toFixed(2) + "%)";
    },

    priceUp(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      if (priceValues.changePrice >= 0) {
        return true;
      } else {
        return false;
      }
    },

    currencyFormatter(value) {
      const options = {
        significantDigits: 2,
        thousandsSeparator: ",",
        decimalSeparator: ".",
        symbol: "",
      };

      if (typeof value !== "number") value = parseFloat(value);
      value = value.toFixed(options.significantDigits);

      const [currency, decimal] = value.split(".");
      return `${options.symbol} ${currency.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        options.thousandsSeparator
      )}${options.decimalSeparator}${decimal}`;
    },
  },
};
</script>

<style>
#download_loader {
  width: 100px;
  height: 100px;
  background-image: url(/dotloader.svg);
  background-repeat: no-repeat;
  background-position: center center;
  display: block;
  margin: auto;
  background-size: 100%;
}
</style>
