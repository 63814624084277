

<template>
  <div>
  <Plotly :data="data" :layout="layout" :display-mode-bar="false"></Plotly>
</div>
</template>
 
<script>
import { Plotly } from 'vue-plotly'

export default {
  name: 'LineChart',
  props: {
    graphData: {}
  },
  components: {
    Plotly
  },
  data() {
    let livexData = this.graphData.livePrices.data.livex;
    let tempDataX = [];
    let tempDataY = [];
    let tempDataX2 = [];
    let tempDataY2 = [];
    let tempDataX3 = [];
    let tempDataY3 = [];

    livexData.forEach(element => {
      tempDataX.push(element.date);
      tempDataY.push(element.price);
      tempDataX2.push(element.date);
      tempDataY2.push(element.price_max);
      tempDataX3.push(element.date);
      tempDataY3.push(element.price_min);
    });

    return {
      data: this.graphData.livePrices.data['price-max']?[
        {
          x: tempDataX2,
          y: tempDataY2,
          type: 'scatter',
          name: "Max Price",
          mode: 'lines',
          marker: { color: '#7295cf' },
          line: { width: 1 }
        },
    
        {
          x: tempDataX3,
          y: tempDataY3,
          name: "Min Price",
          type: 'scatter',
          mode: 'lines',
          marker: { color: '#e8bd61' },
          line: { width: 1 }
        }
      ]:[
        {
          x: tempDataX,
          y: tempDataY,
          type: 'scatter',
          name: "Max Price",
          mode: 'lines',
          marker: { color: '#7295cf' },
          line: { width: 1 }
        }
      ],
      layout: {
        font: {
          family: 'Montserrat',
          size: 12,
          weight: 800,
          color: 'Black'
        },
        title: "Wine Searcher Historical Data",
        width: 850,
        plot_bgcolor: "rgba(0, 0, 0, 0)",
        paper_bgcolor: "rgba(0, 0, 0, 0)",
        xaxis: {
          autorange: true,
          showgrid: false,
          zeroline: true,
          tickangle: 45,
          ticks: ''
        },
        yaxis: {
          autorange: true,
          showgrid: false,
          zeroline: true,
          ticks: ''
        }
      },
      showResetButton: false
    }
  }
}
</script>
 
<style>

</style>
